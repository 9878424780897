import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';
import { NgSelectOption } from '@utils/interface/ngselect.interfaces';

import { ScaTipoRouting } from '~models/carga/interface/ScaTipoRouting';

interface TipoRoutingService {
	findAllByEstado(estado: boolean): Observable<ScaTipoRouting[]>;
	getSelectList(saveInMemory: boolean, addOptionAll: boolean): Observable<NgSelectOption<ScaTipoRouting>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ScaTipoRoutingService implements TipoRoutingService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/scatiporoutinges`;
	private _dataSelect$?: Observable<NgSelectOption<ScaTipoRouting>[]>;

	constructor(protected readonly _http: HttpClient) {}

	findAllByEstado(estado = true): Observable<ScaTipoRouting[]> {
		return this._http.get<ApiResponse<ScaTipoRouting[]>>(`${this._url}/findAllByEstado/${estado.toString()}`).pipe(map((res) => res.data));
	}

	getSelectList(saveInMemory = true, addOptionAll = false): Observable<NgSelectOption<ScaTipoRouting>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstado().pipe(
				map((data) =>
					data.map((item) => ({
						...item,
						value: item.idTipoRouting,
						label: `${item.codigo || ''} - ${item.nombre || ''}`,
					}))
				)
			);

		this._dataSelect$ = this._dataSelect$.pipe(
			map((data) => {
				data = data.filter((d) => !(d.value === null && d.label === '-TODOS-'));

				if (addOptionAll) {
					data.unshift({ ...(<ScaTipoRouting>{}), value: null, label: '-TODOS-' });
				}

				return data;
			}),
			shareReplay(1)
		);

		return this._dataSelect$;
	}

	getSelect(e: ScaTipoRouting | null): NgSelectOption<ScaTipoRouting> | null {
		return e
			? {
					...e,
					value: e.idTipoRouting,
					label: `${e.codigo || ''} - ${e.nombre || ''}`,
			  }
			: null;
	}
}
