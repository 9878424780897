import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';

import { ScaIncidenciaConfiguracion } from '~models/carga/interface/ScaIncidenciaConfiguracion';
import { ScaIncidenciaConfiguracionCustomDto } from '~models/carga/dto/ScaIncidenciaConfiguracionCustomDto';

@Injectable({
	providedIn: 'root',
})
export class ScaIncidenciaConfiguracionService {
	private readonly _url = `${environment.HOST_CARGA}/scaincidenciaconfiguraciones`;

	constructor(private http: HttpClient) {}

	insert(scaIncidenciaConfiguracion: ScaIncidenciaConfiguracion): Observable<ScaIncidenciaConfiguracion> {
		return this.http.post<ApiResponse<ScaIncidenciaConfiguracion>>(`${this._url}`, scaIncidenciaConfiguracion).pipe(
			map((res) => res.data),
			map((data) => data)
		);
	}

	delete(scaIncidenciaConfiguracion: ScaIncidenciaConfiguracion): Observable<ApiResponse<void>> {
		return this.http.delete<ApiResponse<void>>(`${this._url}`, {
			body: scaIncidenciaConfiguracion,
		});
	}

	findAllCustomDto(dto: ScaIncidenciaConfiguracionCustomDto): Observable<ScaIncidenciaConfiguracion[]> {
		return this.http.post<ApiResponse<ScaIncidenciaConfiguracion[]>>(`${this._url}/model-se-ro/findAllCustomDto`, dto).pipe(map((res) => res.data));
	}

	findAllIncidenciaConfiguraciones(dto: ScaIncidenciaConfiguracionCustomDto): Observable<ScaIncidenciaConfiguracion[]> {
		return this.findAllCustomDto(dto);
	}

	updateAllForNroSecuencia(list: ScaIncidenciaConfiguracion[]): Observable<ApiResponse<any>> {
		return this.http.put<ApiResponse<any>>(`${this._url}/updateAllForNroSecuencia`, list);
	}

	deleteAll(list: ScaIncidenciaConfiguracion[]): Observable<ApiResponse<any[]>> {
		return this.http.delete<ApiResponse<any>>(`${this._url}/deleteAll`, { body: list });
	}
}
