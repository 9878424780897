import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';

import { ScaIncidenciaConfiguracionDetalle } from '~models/carga/interface/ScaIncidenciaConfiguracionDetalle';
import { ScaIncidenciaConfiguracion } from '~models/carga/interface';
import { GenericService } from '@utils/classes/GenericService';
import { ConfigService } from '@utils/interface/service.interface';

@Injectable({
	providedIn: 'root',
})
export class ScaIncidenciaConfiguracionDetalleService extends GenericService<ScaIncidenciaConfiguracionDetalle, number> {
	private readonly _url = `${environment.HOST_CARGA}/scaincidenciaconfiguraciondetalles`;

	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByScaIncidenciaConfiguracion(scaIncidenciaConfiguracion: ScaIncidenciaConfiguracion): Observable<ScaIncidenciaConfiguracionDetalle[]> {
		return this._http.post<ApiResponse<ScaIncidenciaConfiguracionDetalle[]>>(`${this._url}/model-se-ro/findAllByScaIncidenciaConfiguracion`, scaIncidenciaConfiguracion).pipe(map((res) => res.data));
	}

	updateAll(list: ScaIncidenciaConfiguracionDetalle[]): Observable<ApiResponse<ScaIncidenciaConfiguracionDetalle[]>> {
		return this._http.put<ApiResponse<ScaIncidenciaConfiguracionDetalle[]>>(`${this._url}/updateAll`, list);
	}

	deleteByScaIncidenciaConfiguracion(scaIncidenciaConfiguracion: ScaIncidenciaConfiguracion): Observable<any> {
		return this._http.delete<ApiResponse<any>>(`${this._url}/deleteByScaIncidenciaConfiguracion`, { body: scaIncidenciaConfiguracion });
	}
}
